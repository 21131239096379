import request from './request';

export default {
  getAlerts: ({ restaurantId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/restaurants/${restaurantId}/alerts`,
    data,
  }),
  addEmail: ({ restaurantId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/restaurants/${restaurantId}/alerts/recipients`,
    data,
  }),
  deleteEmail: ({ restaurantId, ...data }) => request.easytip({
    method: 'delete',
    url: `/v3/restaurants/${restaurantId}/alerts/recipients`,
    data,
  }),
  getEmails: ({ restaurantId, ...data }) => request.easytip({
    method: 'get',
    url: `/v3/restaurants/${restaurantId}/alerts/recipients`,
    data,
  }),
};
