import request from './request';

// Перенесено отсюда https://gitlab.com/easytip.net/tips-admin-frontend/-/merge_requests/477

export default {
  getCheckoutPublicKey: (workplacePayoutId) => request.easytip({
    method: 'get',
    url: '/v1/public/payouts/publicKey',
    params: {
      workplacePayoutId,
    },
    auth: false,
  }),
  addPersonalCard: ({ userId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/users/${userId}/cards`,
    data,
  }),
  createDraftInstantPayout: (userId) => request.easytip({
    method: 'post',
    url: `/v3/users/${userId}/payouts/instant`,
  }),
  makeInstantPayout: ({ userId, instantPayoutId }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/payouts/instant/${instantPayoutId}/confirm`,
  }),
  getPersonalCard: (userId) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/cards/active`,
  }),
  addCorporateCard: ({ workplaceId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${workplaceId}/cards`,
    data,
  }),
  getCorporateCard: (workplaceId) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${workplaceId}/cards/active`,
  }),
  getStaffs: () => request.easytip({
    method: 'get',
    url: '/v1/public/payment/payout/staffs',
    auth: false,
  }),
  payment: (data) => request.easytip({
    method: 'post',
    url: '/v1/public/payment/payout',
    data,
    auth: false,
  }),
  getPayoutPlans: (workplaceId, periodType = '') => request.easytip({
    method: 'get',
    url: `/v3/handbook/workplaces/${workplaceId}/payoutPlans`,
    params: {
      type: periodType,
    },
  }),
  getCurrentPlan: (userId) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/payoutsPlan`,
  }),
  selectNewPlan: ({ userId, payoutPlanId }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/payoutsPlan`,
    data: {
      id: payoutPlanId,
    },
  }),
  getCurrentProgress: (userId) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/payoutsPlan/progress`,
  }),
  createCorporateCardConfirm: ({ workplaceId, cardId }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${workplaceId}/cards/${cardId}/confirmations`,
  }),
  createPersonalCardConfirm: ({ userId, cardId }) => request.easytip({
    method: 'post',
    url: `/v3/users/${userId}/cards/${cardId}/confirmations`,
  }),
  checkCorporateCardConfirm: ({
    workplaceId,
    cardId,
    confirmationId,
    ...data
  }) => request.easytip({
    method: 'put',
    url: `/v3/workplaces/${workplaceId}/cards/${cardId}/confirmations/${confirmationId}`,
    data,
  }),
  checkPersonalCardConfirm: ({
    userId,
    cardId,
    confirmationId,
    ...data
  }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/cards/${cardId}/confirmations/${confirmationId}`,
    data,
  }),
  setSharedCard: (userId) => request.easytip({
    method: 'post',
    url: `/v3/users/${userId}/cards/useSharedCard`,
  }),
  removeSharedCard: (userId) => request.easytip({
    method: 'delete',
    url: `/v3/users/${userId}/cards/useSharedCard`,
  }),
};
