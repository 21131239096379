import instance from './instance';
import request from './request';
import auth from './auth';
import user from './user';
import staff from './staff';
import admin from './admin';
import manager from './manager';
import locations from './locations';
import transaction from './transaction';
import stripe from './stripe';
import support from './support';
import owners from './owners';
import troncMasters from './troncMasters';
import accountants from './accountants';
import alerts from './alerts';
import promotions from './promotions';
import handbook from './handbook';
import checkout from './checkout';
import payouts from './payouts';
import notifications from './notifications';
import menu from './menu';
import reporting from './reporting';
import nymCard from './nymCard';
import otp from './otp';
import subscription from './subscription';

export default {
  instance,
  request,
  auth,
  user,
  staff,
  admin,
  manager,
  locations,
  transaction,
  stripe,
  support,
  owners,
  troncMasters,
  accountants,
  alerts,
  promotions,
  handbook,
  checkout,
  payouts,
  notifications,
  menu,
  reporting,
  nymCard,
  otp,
  subscription,
};
