import request from './request';

export default {
  individual: {
    getAccount: () => request.easytip({
      method: 'get',
      url: '/v1/user/settings/stripe/account',
    }),
    createAccount: (data) => request.easytip({
      method: 'post',
      url: '/v1/user/settings/stripe/account',
      responseType: 'text',
      data,
    }),
    updateAccount: (data) => request.easytip({
      method: 'post',
      url: '/v1/user/settings/stripe/account/link/update',
      responseType: 'text',
      data,
    }),
  },
  venue: {
    getAccount: (locationId) => request.easytip({
      method: 'get',
      url: `/v3/workplaces/${locationId}/stripe/account`,
    }),
    createAccount: ({ locationId, ...data }) => request.easytip({
      method: 'post',
      url: `/v3/workplaces/${locationId}/stripe/account`,
      responseType: 'text',
      data,
    }),
    updateAccount: ({ locationId, ...data }) => request.easytip({
      method: 'put',
      url: `/v3/workplaces/${locationId}/stripe/account/link/update`,
      responseType: 'text',
      data,
    }),
  },
  removeStripeAccountStaff: ({ locationId, staffId }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/staffs/${staffId}/payment/account`,
  }),
};
