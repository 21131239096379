import request from './request';

export default {
  nymCardRegistration: ({
    userId,
    firstName,
    lastName,
    email,
    dob,
    gender,
  }) => request.easytip({
    method: 'post',
    url: `/v4/users/${userId}/registration`,
    data: {
      firstName,
      lastName,
      email,
      dob,
      gender,
    },
  }),
  getNymCardRegistrationToken: ({ userId, sessionId }) => request.easytip({
    method: 'get',
    url: `/v4/users/${userId}/registration/token/${sessionId}`,
  }),
  nymCardSendKycData: ({ userId, data }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}`,
    data,
  }),
  nymCardRunKyc: ({ userId }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}/performKyc`,
  }),
  runOnfidoCheckProcess: ({ userId }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}/runOnfidoCheck`,
  }),
  getUserStatusFromNym: ({ userId }) => request.easytip({
    method: 'get',
    url: `/v4/users/${userId}/status`,
  }),
  getNymCards: ({ userId }) => request.easytip({
    method: 'get',
    url: `/v4/users/${userId}/cards`,
  }),
};
