import fake from './fake';
import request from './request';

export default {
  getLocations: (params) => request.easytip({
    method: 'get',
    url: '/v3/workplaces/short',
    params,
  }),
  createNotification: (data) => request.easytip({
    method: 'post',
    url: '/v3/admin/notifications',
    data,
  }),
  getNotifications: (params) => request.easytip({
    method: 'get',
    url: '/v3/admin/notifications',
    params,
  }),
  getNotification: ({ notificationId }) => request.easytip({
    method: 'get',
    url: `/v3/admin/notifications/${notificationId}`,
  }),
  updateNotification: (data) => request.easytip({
    method: 'put',
    url: '/v3/admin/notifications',
    data,
  }),
  getNotificationStatistics: (notificationId) => request.easytip({
    method: 'get',
    url: `/v3/admin/notifications/${notificationId}/stats`,
  }),
  resendNotificationToSelectedStaffs: (data) => request.easytip({
    method: 'post',
    url: '/v3/admin/notifications/recreate',
    data,
  }),
  getMessages: ({
    userId,
    ...params
  }) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/notifications`,
    params,
  }),
  markMessageAsRead: ({ messageId, userId }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/notifications/${messageId}/markRead`,
  }),
  markMessagesAsRead: ({ userId }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/notifications/markReadAllRead`,
  }),
  getUnreadMessagesCount: ({ userId }) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/notifications/unreadMessagesCount`,
  }),
};
