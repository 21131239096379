import axios from 'axios';
import hex from '@/utils/hex';
import i18n from '@/plugins/i18n';

import { APP_PLATFORM, APP_VERSION } from '@/config/app';
import {
  API_ADMIN_BASEURL,
  API_ADMIN_TIMEOUT,
  API_INTERCOM_BASEURL,
  API_INTERCOM_TIMEOUT,
  API_MEDIA_BASEURL,
  API_MEDIA_TIMEOUT,
} from '@/config/api';

/**
 * Axios instance for easytip api.
 *
 * @var {Axios}
 */
const easytip = axios.create({
  baseURL: API_ADMIN_BASEURL,
  timeout: API_ADMIN_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// For graylog.
easytip.interceptors.request.use((config) => {
  config.headers['X-B3-TraceId'] = hex(16);
  config.headers['X-B3-SpanId'] = hex(16);
  config.headers['X-App-Platform'] = APP_PLATFORM;
  config.headers['X-App-Version'] = APP_VERSION;
  config.headers.Locale = i18n.locale;

  return config;
});

/**
 * Axios instance for intercom.com api.
 *
 * @var {Axios}
 */
const intercom = axios.create({
  baseURL: API_INTERCOM_BASEURL,
  timeout: API_INTERCOM_TIMEOUT,
  method: 'get',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/**
 * Axios instance for media resources in public directory.
 *
 * @var {Axios}
 */
const media = axios.create({
  baseURL: API_MEDIA_BASEURL,
  timeout: API_MEDIA_TIMEOUT,
  method: 'get',
  responseType: 'blob',
  headers: {
    Accept: '*/*',
    'Content-Type': 'image/jpeg',
  },
});

export default {
  easytip,
  intercom,
  media,
};
