import toSQLDate from '@/utils/toSQLDate';
import { getContentDispositionFilename } from '@/utils/parseHeader';
import request from './request';
import fake from './fake';

export default {
  withdrawal: (data) => request.easytip({
    method: 'post',
    url: '/v1/transaction/withdrawal',
    params: data,
  }),
  getStripeListIncoming: ({
    locationId,
    start,
    pageSize,
    startDate,
    endDate,
    groupId,
    ...data
  }) => request.easytip({
    method: 'post',
    url: `/v3/restaurants/${locationId}/transactions/list/incoming`,
    params: {
      start,
      pageSize,
      groupId,
    },
    data: {
      startDate: toSQLDate(startDate),
      endDate: toSQLDate(endDate),
      ...data,
    },
  }),
  getStripeListIncomingByDay: ({
    locationId,
    dateFrom,
    dateTo,
    dateGroup,
    groupId,
  }) => request.easytip({
    method: 'get',
    url: `/v3/restaurants/${locationId}/transactions/list/incoming/group`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
      group: dateGroup,
      groupId,
    },
  }),
  getPayuots: ({
    start,
    pageSize,
    locationId,
    staffId,
    startDate,
    endDate,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/payouts`,
    params: {
      dateFrom: toSQLDate(startDate),
      dateTo: toSQLDate(endDate),
      start,
      pageSize,
    },
  }),
  getTransactionsExcel: ({ locationId, groupId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reports/transactions/excel`,
    timeout: 180000,
    params: {
      groupId,
    },
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }),
  getTransactionsPDF: ({ locationId, groupId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reports/transactions/pdf`,
    timeout: 180000,
    params: {
      groupId,
    },
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }),
  getExportsExcel: ({
    startDate,
    endDate,
    ...data
  }) => request.easytip({
    method: 'post',
    url: '/v3/transactions/report',
    timeout: 180000,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    data: {
      dateFrom: toSQLDate(startDate),
      dateTo: toSQLDate(endDate),
      ...data,
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getTransactionsList: ({
    start,
    pageSize,
    startDate,
    endDate,
    ...data
  }) => request.easytip({
    method: 'post',
    url: '/v3/transactions',
    timeout: 180000,
    params: {
      start,
      pageSize,
    },
    data: {
      dateFrom: toSQLDate(startDate),
      dateTo: toSQLDate(endDate),
      ...data,
    },
  }),
  sendCheckoutRefund: ({ transactionId }) => request.easytip({
    method: 'put',
    url: `/v3/transactions/${transactionId}/refund`,
  }),
  getCheckoutReceipt: ({ transactionId }) => request.easytip({
    method: 'get',
    url: `/v3/transactions/${transactionId}/receipt`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
};
