<template>
  <div
    class="nym-card"
    @click="cardCLickHandler"
  >
    <ui-icon
      name="easytip-horizontal-white"
      class="nym-card__easytip-logo"
      height="19"
      width="70"
    />
    <ui-icon
      name="paypass"
      class="nym-card__paypass"
      height="25"
      width="24"
    />
    <ui-icon
      name="nym-logo"
      class="nym-card__nym-logo"
      size="32"
    />
    <template v-if="isLoading">
      <div class="nym-card__loading">
        <div class="nym-card__loading-helper">
          Almost done...
        </div>
        <div class="nym-card__loading-bar">
          <div
            :style="{ width: `${loadingPercentage}%` }"
            class="nym-card__loading-progress"
          >
            <span v-if="loadingPercentage > 15">
              {{ loadingPercentage }} %
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="showInfo" class="nym-card__number">
        1234 5678 9876 7389
      </div>
      <div v-else class="nym-card__number">
        <span class="nym-card__number-dots">● ● ● ●</span> 7389
      </div>
      <div v-if="showInfo" class="nym-card__info">
        <div class="nym-card__info-row">
          <div class="nym-card__info-data">
            <div class="nym-card__info-title">
              Card Holder name
            </div>
            <div class="nym-card__info-value">
              {ABCDEFGHIJK LMNOPQRS}
            </div>
          </div>
        </div>
        <div class="nym-card__info-row">
          <div class="nym-card__info-data">
            <div class="nym-card__info-title">
              Expiry date
            </div>
            <div class="nym-card__info-value">
              {02/30}
            </div>
          </div>
          <div class="nym-card__info-data">
            <div class="nym-card__info-title">
              CVV2
            </div>
            <div class="nym-card__info-value">
              {123}
            </div>
          </div>
        </div>
      </div>
      <ui-icon-button
        v-if="showInfo"
        name="crossed-eye"
        class="nym-card__crossed-eye"
        size="24"
        @click.stop="hideInfo"
      />
      <ui-icon
        name="visa"
        class="nym-card__visa"
        height="15"
        width="48"
      />
    </template>
  </div>
</template>

<script>
const MAX_PERCENTAGE = 75;
const LOADING_COMPLETED_PERCENTAGE = 100;

export default {
  name: 'NymCard',

  props: {
    isLoading: Boolean,
    loadingCompleted: Boolean,
    loadingDuration: {
      type: Number,
      default: 120,
    },
  },

  data() {
    return {
      showInfo: false,
      remainingLoadingTime: this.loadingDuration,
    };
  },

  computed: {
    loadingPercentage() {
      return this.loadingCompleted
        ? LOADING_COMPLETED_PERCENTAGE
        : Math.ceil(MAX_PERCENTAGE - (this.remainingLoadingTime / this.loadingDuration) * MAX_PERCENTAGE);
    },
  },

  methods: {
    cardCLickHandler() {
      if (!this.showInfo) {
        this.showInfo = true;
      }
    },
    hideInfo() {
      this.showInfo = false;
    },
    startTimer() {
      const intervalID = setInterval(() => {
        this.remainingLoadingTime -= 1;

        if (this.remainingLoadingTime === 0) {
          clearInterval(intervalID);
          this.$emit('time-over');
        }
      }, 1000);
    },
  },

  mounted() {
    if (this.isLoading) {
      this.startTimer();
    }
  },
};
</script>
