import { getContentDispositionFilename } from '@/utils/parseHeader';
import request from './request';

export default {
  getMonthlyReport: (data) => request.easytip({
    method: 'post',
    url: '/v3/reporting/monthly/download',
    headers: {
      Accept: '*/*',
    },
    responseType: 'text',
    data,
  }).then((res) => {
    return {
      data: new Blob([res.data], { type: 'text/html' }),
      filename: getContentDispositionFilename(res.headers),
    };
  }),
};
