/* eslint-disable import/no-cycle */

import request from './request';

export default {
  login: ({ type, ...data }) => request.easytip({
    method: 'post',
    url: type ? `/v1/auth/signin/${type}` : '/v1/auth/signin',
    data,
  }),
  loginAs: (data) => request.easytip({
    method: 'post',
    url: '/v1/viewas/signin',
    data,
  }),
  recovery: (data) => request.easytip({
    method: 'post',
    url: '/v1/public/account/restore',
    data,
    auth: false,
  }),
  recoveryConfirm: (data) => request.easytip({
    method: 'post',
    url: '/v1/public/account/restore/confirm',
    data,
    auth: false,
  }),
  refresh: (data) => request.easytip({
    method: 'post',
    url: '/v1/auth/refresh',
    auth: false,
    data,
  }),
  logout: (data) => request.easytip({
    method: 'post',
    url: '/v1/auth/logout',
    data,
  }),
  logoutAs: (data) => request.easytip({
    method: 'post',
    url: '/v1/viewas/logout',
    data,
  }),
  logoutTotal: () => request.easytip({
    method: 'post',
    url: '/v1/auth/logout/total',
  }),
};
