/**
 * Fake api promise.
 *
 * @param  {Object} data
 * @param  {number} timeout
 * @param  {boolean} error
 * @return {Promise}
 */
function fake(data, timeout, error = false) {
  const req = new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(data);
      } else {
        resolve(data);
      }
    }, timeout);
  });

  req.cancel = function FakeCancel() { };

  return req;
}

export default fake;
