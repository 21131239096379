import request from './request';

export default {
  getStaffTypes: () => request.easytip({
    method: 'get',
    url: '/v1/public/handbook/staffTypes',
    auth: false,
  }),
  getVersion: () => request.easytip({
    method: 'get',
    url: '/v1/public/handbook/versions',
    auth: false,
  }),
  getCountries: (params) => request.easytip({
    method: 'get',
    url: '/v1/public/handbook/country',
    params,
    auth: false,
  }),
  getRemoveReasons: () => request.easytip({
    method: 'get',
    url: '/v3/handbook/workplaces/removeReasons',
  }),
};
