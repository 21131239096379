import { DateTime } from 'luxon';
import toSQLDate from '@/utils/toSQLDate';
import request from './request';
import fake from './fake';

/**
 * Prepare payout data.
 *
 * @param  {Object} item
 * @return {Object}
 */
function transformPayoutData({ workDay, ...params }) {
  return {
    ...params,
    value: workDay.hours,
    periodEndDate: toSQLDate(workDay.date),
  };
}

export default {
  registerStaff: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/staffs/registration`,
    data,
  }),
  getStaffs: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs`,
    params,
  }),
  getStaffsPage: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/workplaces/${locationId}`,
    params,
  }),
  getDistributionStaffs: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/staffs`,
    params,
  }),
  getStaffsBasicInfo: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/staffs`,
    params,
  }),
  updateStaff: ({ locationId, staffId, ...data }) => request.easytip({
    method: 'put',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}`,
    data,
  }),
  allowSharedCard: ({ locationId, staffId }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/allowedSharedCard`,
  }),
  disallowSharedCard: ({ locationId, staffId }) => request.easytip({
    method: 'delete',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/cards/allowedSharedCard`,
  }),
  getStaffSales: ({
    salesId,
    dateFrom,
    dateTo,
    ...params
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/ranking`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
      ...params,
    },
  }),
  removeStaff: ({
    locationId,
    staffId,
    deactivateDate,
    ...params
  }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/staffs/${staffId}`,
    params: {
      deactivateDate: toSQLDate(deactivateDate),
      ...params,
    },
  }),
  removeStaffFromGroup: ({ locationId, staffId, groupId }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/staffs/${staffId}/groups/${groupId}`,
  }),
  addStaffToGroup: ({ locationId, staffId, groupId }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/staffs/${staffId}/groups/${groupId}`,
  }),
  addPayroll: ({ locationId, staffId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/payroll`,
    data,
  }),
  getStaffPayouts: ({ locationId, dateFrom, dateTo }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/staffs/payout`,
    timeout: 180000,
    params: {
      dateFrom: DateTime.fromJSDate(dateFrom).toFormat('yyyy-LL-dd'),
      dateTo: DateTime.fromJSDate(dateTo).toFormat('yyyy-LL-dd'),
    },
  }),
  calculateStaffPayouts: ({ locationId, data }) => request.easytip({
    method: 'post',
    url: `/v4/workplaces/${locationId}/common/payouts/calculate`,
    timeout: 180000,
    data: data.map(transformPayoutData),
  }),
  createDraftStaffPayouts: ({ locationId, data }) => request.easytip({
    method: 'post',
    url: `/v4/workplaces/${locationId}/common/payouts`,
    timeout: 180000,
    data: data.map(transformPayoutData),
  }),
  confirmStaffPayouts: ({ locationId, draftPayoutCalculationId }) => request.easytip({
    method: 'put',
    url: `/v4/workplaces/${locationId}/common/payouts/${draftPayoutCalculationId}`,
  }),
  sendStaffPayouts: ({ locationId, data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/staffs/payout`,
    timeout: 180000,
    data: data.map(transformPayoutData),
  }),
  getMinDistributionsDate: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/common/minNoneDistributionDate`,
  }),
  getDistributions: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/distributions`,
  }),
  updateDistributions: ({ locationId, data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/distributions`,
    data,
  }),
  getStaffSlots: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/staffSlots`,
  }).then(({ data, ...other }) => {
    data.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

    return {
      data,
      ...other,
    };
  }),

  getReviews: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/reviews`,
  }),
  updateReviews: ({ locationId, data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/reviews`,
    data,
  }),

  getLocation: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}`,
  }),
  deleteLocation: ({ locationId, data = null }) => request.easytip({
    method: 'delete',
    url: `/v3/workplaces/${locationId}`,
    data,
  }),
  createLocation: ({ payrollCustomDate, ...data }) => request.easytip({
    method: 'post',
    url: '/v3/workplaces',
    data: {
      ...data,
      payrollCustomDate: payrollCustomDate
        ? toSQLDate(payrollCustomDate)
        : payrollCustomDate,
    },
  }),
  updateLocation: ({ id, payrollCustomDate, ...data }) => request.easytip({
    method: 'put',
    url: `/v3/workplaces/${id}`,
    data: {
      id,
      payrollCustomDate: payrollCustomDate
        ? toSQLDate(payrollCustomDate)
        : payrollCustomDate,
      ...data,
    },
  }),
  deactivateLocation: ({ id }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${id}`,
  }),
  activateLocation: ({ id, onboardingDate, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${id}/activate`,
    params: {
      onboardingDate: toSQLDate(onboardingDate),
    },
  }),

  getLocations: (params) => request.easytip({
    method: 'get',
    url: '/v3/workplaces',
    params,
  }).then(({
    data: {
      data: items,
      ...other
    },
  }) => ({
    data: {
      data: items.filter(({ id }) => id !== '999'),
      ...other,
    },
  })),
  getStaffQR: ({ staffId, locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/staffs/${staffId}/qr`,
    params,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }),
  getGroupQR: ({ groupId, locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/groups/${groupId}/qr`,
    params,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }),
  getQR: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/qr`,
    params,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }),
  getImage: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/image`,
    responseType: 'blob',
  }),
  uploadImage: ({ locationId, file }) => {
    const data = new FormData();

    data.append('file', file);

    return request.easytip({
      method: 'post',
      url: `/v2/restaurants/${locationId}/image`,
      data,
    });
  },
  getPartnerImage: ({ partnerId }) => request.easytip({
    method: 'get',
    url: `/v4/partner/${partnerId}/image`,
    responseType: 'blob',
  }),
  uploadPartnerImage: ({ partnerId, file }) => {
    const data = new FormData();

    data.append('file', file);

    return request.easytip({
      method: 'post',
      url: `/v4/partner/${partnerId}/image`,
      data,
    });
  },
  getTotal: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/transactions/total`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getBonus: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/transactions/bonus`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getPromotions: ({
    locationId,
    status,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/promotions`,
    params: {
      status,
    },
  }),
  getRating: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/ratings/avg`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getLastReviews: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reviews/statistic/lastReviews`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getTotalRatings: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reviews/statistic/ratings`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getTopCompliments: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reviews/statistic/topCompliments`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getTopComplaints: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reviews/statistic/topComplaints`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getBestPerformers: ({
    salesId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/bestPerformers`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getWorstPerformers: ({
    salesId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/worstPerformers`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getNewClients: ({
    salesId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/newWorkplace`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getGmv: ({
    salesId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/gmv`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getStaffRegistrations: ({
    salesId,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/incompleteStaffReg`,
  }),
  getIncompleteKYC: ({
    salesId,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/incompleteKYC`,
  }),
  getRestrictedSoonAccounts: ({
    salesId,
  }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/restrictedSoonAccounts`,
  }),
  getAccountantRegistrations: ({ salesId }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/missAccountant`,
  }),
  getOverduePayouts: ({ salesId }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/overdueTaxCalculation`,
  }),
  getOverdueDistributions: ({ salesId }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/overdueDistributions`,
  }),
  getUnclaimedTaxes: ({ salesId }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/metrics/unclaimedTaxes`,
  }),
  getTopEarners: ({
    locationId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reviews/statistic/topWaiterListByTips`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getBalance: ({ locationId, ...params }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/common/balance`,
    params,
  }),
  getVenueCharge: ({
    locationId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/restaurants/${locationId}/transactions/total/venue`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getPayoutTaxesHistory: ({ locationId, periodId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/taxes/periods/${periodId}/historical`,
  }),
  getPayoutTaxesPeriods: ({ locationId, date }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/common/periods`,
    params: {
      searchDate: toSQLDate(date),
    },
  }),
  getPayoutHistory: ({
    locationId,
    periodId,
    start,
    pageSize,
  }) => request.easytip({
    method: 'get',
    url: `/v4/workplaces/${locationId}/common/periods/${periodId}/history`,
    timeout: 90000,
    params: {
      start,
      pageSize,
    },
  }),
  getPayoutStartDate: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/taxes/calculations/currentPeriod`,
  }),
  getPayoutTaxesCalculation: ({ locationId, dateFrom, dateTo }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/taxes/calculations`,
    timeout: 180000,
    params: {
      dateFrom,
      dateTo,
    },
  }),
  getPayoutPeriods: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/taxes/periods`,
  }),
  getPayoutYears: ({
    isCheckout,
    locationId,
    staffId,
    userId,
  }) => request.easytip({
    method: 'get',
    url: isCheckout
      ? `/v3/users/${userId}/payouts/years`
      : `/v3/workplaces/${locationId}/staffs/${staffId}/payouts/years`,
  }),
  getPayoutPeriod: ({ locationId, periodId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/taxes/periods/${periodId}`,
  }),
  getYears: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/payouts/years`,
  }),
  getPayouts: ({
    locationId,
    ...params
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/payouts`,
    params,
  }),
  sendPayoutPeriod: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/taxes/calculations`,
    data,
  }),
  getAutomationPayout: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/taxes/automation`,
  }),
  sendAutomationPayout: ({ locationId, startDate, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/taxes/automation`,
    data: {
      ...data,
      startDate: toSQLDate(startDate),
    },
  }),
  getGroups: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/groups`,
  }),
  createGroup: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/groups`,
    data,
  }),
  getSublocations: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/sublocations`,
  }),
  setSublocation: (locationId, data) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/sublocations`,
    data,
  }),
  updateGroup: ({ locationId, groupId, ...data }) => request.easytip({
    method: 'put',
    url: `/v3/workplaces/${locationId}/groups/${groupId}`,
    data,
  }),
  removeGroup: ({ locationId, groupId }) => request.easytip({
    method: 'delete',
    url: `/v3/workplaces/${locationId}/groups/${groupId}`,
  }),
  getUserLocations: ({ userId }) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/workplaces`,
  }),
  setLocation: ({ userId, locationId }) => request.easytip({
    method: 'put',
    url: `/v3/users/${userId}/workplaces/${locationId}`,
  }),
  getPayButtons: (params) => request.easytip({
    method: 'get',
    url: '/v3/handbook/payButtonsList',
    params,
  }),
  getPayoutPlanSetupList: (params) => request.easytip({
    method: 'get',
    url: '/v3/handbook/payoutPlanSetupList',
    params,
  }),
  getSalesPeople: () => request.easytip({
    method: 'get',
    url: '/v2/sales',
  }),
  getHeadSalesPeople: ({ salesId }) => request.easytip({
    method: 'get',
    url: `/v2/sales/${salesId}/head/listSales`,
  }),
  getDistributionSchemes: () => request.easytip({
    method: 'get',
    url: '/v3/handbook/distributionSchemesList',
  }),
  uploadFile: ({
    file,
    locationId,
    dateFrom,
    dateTo,
  }) => {
    const data = new FormData();
    data.append('file', file);

    return request.easytip({
      method: 'post',
      url: `/v2/restaurants/${locationId}/staffs/payout/parseDataFromExcelForDistributed`,
      params: {
        dateFrom: toSQLDate(dateFrom),
        dateTo: toSQLDate(dateTo),
      },
      data,
    });
  },
  sendRegistrationMessage: ({ locationId, staffId }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/resendInvitation`,
  }),
  getRegistrationMessage: ({ locationId, staffId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/resendInvitation`,
  }),
  getTurnOffPayReasons: (locationId) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/reasons`,
  }),
  setTurnOffPayReason: (locationId, data) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/reasons`,
    data,
  }),
  getPaySettingsConfig: (locationId) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/pay-settings`,
  }),
  setPaySettingsConfig: (locationId, data) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/pay-settings`,
    data,
  }),
};
