import toSQLDate from '@/utils/toSQLDate';
import { getContentDispositionFilename } from '@/utils/parseHeader';
import request from './request';

export default {
  getStaffHistoryExcel: ({
    locationId,
    dateFrom,
    dateTo,
    staffId,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/payouts/history/excel`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getStaffHistoryPdf: ({
    locationId,
    dateFrom,
    dateTo,
    staffId,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/payouts/history/pdf`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getLocationHistoryExcel: ({
    isOwnerUAECommon,
    locationId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: isOwnerUAECommon
      ? `/v3/workplaces/${locationId}/payouts/history/excel`
      : `/v3/workplaces/${locationId}/taxes/history/excel`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getLocationHistoryPdf: ({
    isOwnerUAECommon,
    locationId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: isOwnerUAECommon
      ? `/v3/workplaces/${locationId}/payouts/history/pdf`
      : `/v3/workplaces/${locationId}/taxes/history/pdf`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getCheckoutHistoryExcel: ({
    dateFrom,
    dateTo,
    userId,
  }) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/payouts/history/excel`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getCheckoutHistoryPdf: ({
    dateFrom,
    dateTo,
    userId,
  }) => request.easytip({
    method: 'get',
    url: `/v3/users/${userId}/payouts/history/pdf`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  getPayoutDetails: ({
    isOwnerUAECommon,
    locationId,
    userId,
    payoutId,
  }) => request.easytip({
    method: 'get',
    url: isOwnerUAECommon
      ? `/v3/workplaces/${locationId}/payouts/${payoutId}`
      : `/v3/users/${userId}/payouts/${payoutId}`,
  }),
  getPayoutDetailsExcel: ({
    isOwnerUAECommon,
    locationId,
    userId,
    payoutId,
  }) => request.easytip({
    method: 'get',
    url: isOwnerUAECommon
      ? `/v3/workplaces/${locationId}/payouts/${payoutId}/export/excel`
      : `/v3/users/${userId}/payouts/${payoutId}/export/excel`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
};
