<template>
  <div class="document">
    <div class="updated-date">
      <span>Last updated: 25.03.2024</span>
    </div>
    <div class="document__content">
      <p>
        These terms and conditions form the Agreement between Easy Tip and the Cardholder, and shall be referred to as the “<b>Agreement</b>” or the “<b>Terms and Conditions</b>”.
      </p>
      <p>
        Your Account and Card are issued by Nymcard Payments Services LLC <a href="https://nymcard.com">https://nymcard.com</a> ("<b>NymCard</b>") and powered by QR Tip Information Technology LLC <a href="https://www.easytip.net">www.easytip.net</a> (“<b>EasyTip</b>” or the “<b>EasyTip App</b>”) who provides the Online Services (defined below) in their capacity as the Agent of NymCard.
      </p>
      <p>
        Nymcard Payments Services LLC holds a license from the Central Bank of UAE under the Retail Payment Services and Card Scheme (RPSCS) Regulation (license number 07.01.02.001.2022.02) dated 01.06.2022 and is licensed Card Issuer by Mastercard / Visa. NymCard and its affiliates shall be also providing you with Card Services.
      </p>
      <p>
        NymCard will be responsible for executing Transactions (as defined below) and shall ensure that it has all the necessary approvals required by the Central Bank of the UAE in relation to conducting any regulated activities in the UAE.
      </p>
      <p>
        By requesting the issuance of and using the Account and Card (each as defined below) issued by the Issuer, you confirm that you understand, accept and shall comply with these Terms and Conditions. Your Card is non-transferable, and it may be cancelled, suspended, or revoked at any time without prior notice subject to applicable laws and these Terms and Conditions.
      </p>
      <h2>Definitions and Interpretation</h2>
      <p>
        In these Terms and Conditions, the following terms shall have the following meanings:
      </p>
      <p>
        “<b>Account</b>” — means the account and Card held and maintained by you which is used for executing Transactions.
      </p>
      <p>
        “<b>AED</b>” — means United Arab Emirates Dirhams, the lawful currency of United Arab Emirates.
      </p>
      <p>
        “<b>Agent</b>” — means, for the purposes of these Terms and Conditions, EasyTip providing the following services on behalf of NymCard: Payment Initiation, collection of Personal Data for the purpose of Identification Checks and providing you with Online Services.
      </p>
      <p>
        “<b>ATM</b>” — means an Automated Teller Machine.
      </p>
      <p>
        “<b>Available Funds</b>” — means the existing balance in the Account and Card that is available for utilization or withdrawal, as reduced by any Transaction amounts which have been reserved, blocked, are pending or have otherwise not been processed or released.
      </p>
      <p>
        “<b>Base Currency</b>” — refers to the default currency for any Transaction performed by you.  Any Transaction in a currency not available on the Card will be converted into the Base Currency. The Base Currency for your Account and Cards is AED.
      </p>
      <p>
        “<b>Business Day</b>” — means any day of the week, excluding Fridays after 1pm, Saturdays, and Sundays and statutory holidays in the United Arab Emirates.
      </p>
      <p>
        “<b>Card</b>” — means each card issued to you or a supplementary Cardholder, whether with or without your name being printed or embossed on the physical Card and which may also be in the form of a virtual card. Any references to "Card" also include any Replacement Card, and/or any Supplementary Technology deployed by us for use with your Card where appropriate.
      </p>
      <p>
        “<b>Card Currency</b>” — means Emirati Dirhams (AED) and any additional currency that we may make available in connection with the Card from time to time.
      </p>
      <p>
        “<b>Card Funds</b>” — means the aggregate sum of all the funds loaded onto the Card.
      </p>
      <p>
        “<b>Card Number</b>” — means the unique sixteen-digit number printed or embossed on the face or reverse of the Card, or as displayed on the app.
      </p>
      <p>
        “<b>Card Services</b>” — means any services provided by us, our third-party service providers, or the Program Manager, in connection with the Card, including call centre services.
      </p>
      <p>
        “<b>Card User, User, Customer</b>” — means the authorized person whose name is on the Account and Card, which is used to execute Transactions.
      </p>
      <p>
        “<b>Charges</b>” — means amounts (including any fees or charges) payable by you arising from usage of the Card Services under these Terms and Conditions.
      </p>
      <p>
        “<b>Country</b>” — means the United Arab Emirates (UAE).
      </p>
      <p>
        “<b>Customer Support</b>” — means the department made available for you to handle any inquiries, complaints and general Card support.
      </p>
      <p>
        “<b>E-commerce website</b>” — means any internet website where a Merchant accepts use of the Card for purchase of goods and services.
      </p>
      <p>
        “<b>Fee Schedule</b>” — means the schedule of fees attached as Annex 1 to these Terms and Conditions, as such schedule may be amended by notice to you from time to time.
      </p>
      <p>
        “<b>Identification Checks</b>” — means checks carried out in order to obtain, verify and record information that identifies each person who purchases or loads funds onto a Card. We may ask you for your name, address, date of birth and other information, directly or indirectly, that will allow us to reasonably identify you. We will ask to see your passport, ID, and/or other identifying documents where required. The activation of the Card is subject to successful verification of your identity by the Issuer.
      </p>
      <p>
        “<b>Issuer</b>” — means Nymcard Payment Services LLC (“<b>NymCard</b>”) a company licensed by the Central Bank of UAE under license number 07.01.02.001.2022.02 with registered address Office at Al Safouh Second, Al Salam Tower, 27th Floor, 2703, P.O. Box 451240, Dubai, United Arab Emirates.
      </p>
      <p>
        “<b>Limit</b>” — means the limit set by us for usage on the Card for cash withdrawals or purchases of goods and services, subject to the Available Funds. We may change such limit at any time at our sole discretion.
      </p>
      <p>
        “<b>Load</b>” — means to add money to the Card, and “<b>Loaded</b>” and “<b>Loading</b>” will be construed accordingly.
      </p>
      <p>
        “<b>Merchant</b>” — means any establishment, retailer, corporate entity, person or other virtual establishment, supplying goods and/or services, which accepts the Card as a mode of payment.
      </p>
      <p>
        “<b>Online Services</b>” — means optional mobile applications or websites that may be made available to you to manage your Card and Account. Online Services may enable you to use your registered mobile device to manage your Account and Card, perform Transactions and initiate Customer Support communications.
      </p>
      <p>
        “<b>Payment Initiation</b>” — means the initiation of a Payment Order at the request of the User with respect to your Account and Card held with NymCard. For the avoidance of doubt, Payment Initiation does not include the holding and maintenance of funds at any time.
      </p>
      <p>
        “<b>Payment Order</b>” — means a request by the User to initiate a payment to a Merchant for the purchase of goods or services, to load your Card from your Account or to transfer funds to another User within the same application provided as part of the Online Services.
      </p>
      <p>
        “<b>Personal Data</b>” — means any information and data in any form that relates to a natural person, which either directly or indirectly, in combination with other information available or likely to be available with us, is capable of identifying such person (e.g., telephone number, name, address, transaction history etc.).
      </p>
      <p>
        “<b>PIN</b>” — means a unique Personal Identification Number allocated to each Card.
      </p>
      <p>
        “<b>POS Terminal</b>” — means a point of sale or an electronic terminal available at Merchants, whether local or international, capable of processing Transactions.
      </p>
      <p>
        “<b>Primary Account Holder</b>” or “<b>Primary Cardholder</b>” — means the person whose name is used to open an Account and the holder of the Card to which any Supplemental Cards are linked.
      </p>
      <p>
        “<b>“Program Manager</b>” — means NymCard and NymCard's affiliates providing Card Services, Customer Support and such other services as determined by us.
      </p>
      <p>
        “<b>Replacement Card</b>” — means a substitute Card which may be issued to you in the event of the loss or theft of your Card.
      </p>
      <p>
        “<b>Restricted Use</b>” — means us of the Account and Card in countries and regions where such use is currently prohibited. Countries and regions with restrictions include but are not limited to Iran, North Korea, Crimea and Sudan. Restricted Use may also apply to certain Merchant Category Codes (MCCs). These restrictions may be amended from time to time by us in our sole discretion.
      </p>
      <p>
        “<b>Shortfall</b>” — means circumstances where the Available Funds and/or a particular currency in the Card drops below zero (0).
      </p>
      <p>
        “<b>Supplemental Card</b>” — means an additional card linked to the Card of the Primary Cardholder.
      </p>
      <p>
        “<b>Supplementary Cardholder</b>” — means a person nominated by the Primary Cardholder to use the Card and in whose name we may issue a Supplementary Card.
      </p>
      <p>
        “<b>Supplementary Technology</b>” — means any technologies or devices that we may introduce for use together with, or as a replacement for, your Card and which may include, without limitation, wristbands and mobile devices.
      </p>
      <p>
        “<b>Supported Currency</b>” — means AED or any other Currency made available by us from time to time.
      </p>
      <p>
        “<b>Transaction</b>” — means Transactions made by using the Account or Card, whether with or without use of the Card Number, PIN or signature, and regardless of whether any slip or other voucher is signed by you and includes any cash withdrawal made available by us or the amount charged (or otherwise debited to the Card) by us or a Merchant for any goods, services or benefits (whether or not such service was utilized by you) obtained by the use of the Card, the Card Number or the PIN or in any other manner, including mail, telephone, facsimile or internet orders.
      </p>
      <p>
        “<b>Unsupported Currency</b>” — means any currency other than a Supported Currency.
      </p>
      <p>
        “<b>We</b>” or “<b>Us</b>” — means the provider of the EasyTip App (Easy Tip), the Program Manager (NymCard and its affiliates); the Issuer (NymCard); and any other partner or third party we may work with in respect to the issuance, management, or distribution of Cards and “<b>our</b>” shall be construed accordingly.
      </p>
      <p>
        “<b>Website</b>” — means the website at the URL or any future URL applicable to your Account and Card.
      </p>
      <p>
        “<b>you</b>” — means the owner of the Account and the person legally responsible for all Cards issued, and "your" shall be construed accordingly.
      </p>

      <h2 id="section-1">1. Applying for the Card</h2>
      <p>
        1.1. To apply for an Account and Card as a Primary Account Holder holder, you must be at least eighteen (18) years old and a United Arab Emirates (UAE) resident. We may ask to see evidence of who you are and your address. We may ask for documentary evidence to prove this and/or we may carry out Identification Checks on you electronically. We may also ask for documentary evidence for any Supplementary Cardholder.
      </p>
      <p>
        1.2. When we perform electronic Identification Checks, Personal Data provided by you may be disclosed to a registered third-party agency who may keep a record of that information. By purchasing or requesting our services, you agree to your Personal Data as well as any Supplementary Cardholder’s Personal Data being disclosed to such agencies for this purpose.
      </p>
      <p>
        1.3. Your Card will be issued to you on the basis that the required information that you have provided to us is accurate and valid. You agree to inform us of any changes to your Personal Data when such changes occur, or as and when we may request of you to confirm or update such information from time to time.
      </p>
      <p>
        1.4. The approval of a Card and Account is subject to successful verification of your or a Card User’s identity by the Issuer.
      </p>
      <p>
        1.5. Activation of your Account will be affected once you load funds into the Account.
      </p>
      <p>
        1.6. Activation of supplementary Cards will be affected upon activating them through Card activation channels.
      </p>

      <h2 id="section-2">2. The Account and the Card</h2>
      <p>2.1. The Account and Card are not a checking, savings, or other bank account and are not connected in any way to any other account.</p>
      <p>2.2. There will be no interest earned on any Available Funds on the Card or in the Account issued to you.</p>
      <p>2.3. The Card allows you or the Card User to access the Available Funds which you or the Card User have Loaded on the Card and Available Funds can be used to buy goods and services in-person and online, and to withdraw cash at ATMs, including other supported functions.</p>
      <p>2.4. The ability to spend the Available Funds is determined by the Limits set out as part of our program, as well as any limits you may have defined. Various Limits may apply to the Card in respect of individual Transactions, as well as daily and weekly transacted amounts based on the limits you place on the Cards as well as the Card Program Limits.</p>
      <p>2.5. You confirm that you have read and agreed to the schedule of fees, commission and charges for usage of the Card for any Transaction mentioned in the Fee Schedule.</p>
      <p>2.6. As the applicant, you are responsible for all Cards issued to you under this Agreement and for any fees or charges that these Cards may incur.</p>
      <p>2.7. We may, in our sole discretion, issue, restrict or withdraw any Cards that relate to your Account.</p>

      <h2 id="section-3">3. Using the Card</h2>
      <p>3.1. A Card may only be used in accordance with these Terms and Conditions, and we reserve the right to restrict the services and facilities related to the issuance and usage of a Card in our sole discretion and terms.</p>
      <p>3.2. A Card is only meant for the Card Holder’s use and expires on the date on the front of the Card in the EasyTip App and may be extended for a limited period subject to these Terms and Conditions. Upon expiry, you may be able to transfer the remaining funds to a new Card in accordance with our prevailing policy.</p>
      <p>3.3. Subject to an Account holding sufficient Available Funds and to these Terms and Conditions, a Card can be used worldwide at ATMs, POS Terminals or E-commerce Website or any card operated machine or device, whether belonging to us or other participating banks or financial institutions where logos appearing on the Card are displayed/ accepted and any other services authorized on the Card by us. The Card may also be used with any Merchant that accepts the Transaction for processing or displays the logo appearing on the Card. The Card may be used for retail purchases, cash withdrawals, the purchase of goods and services via the internet, telephone, facsimile and mail order or for any other services approved by us, for Transaction values not exceeding the Limit (subject always to sufficient Available Funds) from time to time.</p>
      <p>3.4. If the Card User wants to make a Transaction using their Card or Card details, they will need to authorize such Transaction by using their Card, a PIN number, their signature, a security code, a password, biometric data or other personal identifier, or a combination of these. Where contactless payments are available, the Card User can also authorize a Transaction by touching the payment terminal with their Card or mobile device. We cannot stop or cancel a Transaction once the Card User has authorized it.</p>
      <p>3.5. The Available Funds will be reduced by the full amount of each Transaction plus any applicable Charges.</p>
      <p>3.6. We are entitled to set off any sum of money on the Card due from you to us against any positive balance in the Account held by you with us.</p>
      <p>3.7. The Card can only be used if it has sufficient Available Funds, and the Card User is solely responsible for ensuring that there are sufficient Available Funds for an intended Transaction. If, for any reason whatsoever, an intended Transaction causes the Card to be debited in excess of the Available Funds (whether by use of the Card or towards any fees or charges), you are responsible for any Shortfall.</p>
      <p>3.8. Due to restrictions, the Card may not operate in some countries or regions and may not be accepted at the locations of some Merchant who are not in compliance with our internal policies or whose MCC’s have been blocked by us.</p>
      <p>3.9. Merchants that accept the Card are required to seek authorization from us or through any appointed payment processor for all Transactions that are made and we cannot stop a Transaction once authorized. Some Merchants may not be able to authorize a Transaction if they cannot obtain an online authorization from us.</p>
      <p>3.10. Some Merchants may place a hold on an amount greater than the value of Transaction that the Card User wishes to make, which requires a pre-authorization for such an amount. This requires the Card User to have Available Funds greater than the value of the expected Transaction. This extra amount will be reserved to cover things like tips and gratuities, temporarily reducing the balance available on the Card. However, the Card will only be charged for the actual and final amount of the Transaction you make. Excess amounts held beyond the final Transaction amount will be released to you within 20 days'.</p>
      <p>3.11. In certain circumstances, a third party paying a tip to a cardholder (a “<b>Tipper</b>”) may elect to pay agreed fees for the use of the EasyTip App (“<b>Platform fees</b>”) rather than requiring the Card Holder to pay such Platform fees.  For example, when paying a tip or gratuity to a Card Holder, a customer may choose to pay Platform fees rather than have them deducted from the tip or gratuity, such that the Card Holder will receive the full amount of the intended tip or gratuity. Where a third party so elects, the relevant Platform fees will be transferred to the Card Holder's Account together with the tip or gratuity.  Such Platform fees will then be deducted from the Card Holder's account and transferred to EasyTip.  The Card Holder irrevocably authorises us to deduct such processing and related fees in the manner described in this paragraph.</p>
      <p>3.12. You acknowledge that the Available Funds for the Card may be different from the actual balance amount as displayed through any channel, due to pending Transactions or other blocked amounts (being amounts transacted but not yet processed) relevant to the Card.</p>
      <p>3.13. Our record of any Transaction shall be final and binding. You will not be provided with a printed statement, but may check your Available Funds via Online Services. You may also be able to make a balance enquiry at some ATMs, although availability is dependent on the country and the ATM used and the ATM operator may charge a fee for such balance enquiries. It is your responsibility to keep track of the Available Funds. You acknowledge that the amount stated on an ATM screen or a printed slip or receipt advice shall not for any purpose whatsoever be taken as a conclusive or up to date statement of the Available Funds.</p>
      <p>3.14. If you are entitled to a refund for goods or services purchased using the Card, or another credit for any other reason.  Any such refund or credit will be made to the Card as when the relevant funds are received, in the applicable Supported Currency, thereby increasing the Available Funds.</p>
      <p>3.15. You agree not to use the Card, or permit any other person to use the Card, in any manner that could damage, disable, overburden or impair the Card. You also agree not to use the Card, or permit any other person to use the Card,  access or purchase  goods, whether from physical locations or Websites,  engaged in unlawful activity or from any Merchants to whom we have restricted access under our policies in effect from time to time.</p>
      <p>3.16. You must comply with all applicable laws and regulations (including any foreign exchange controls) with respect to the Card in the issuing country and the country of use. We reserve the right to suspend the Card, terminate this Agreement and exercise any other remedies available to us in the event that you fail  to comply with any part of this provision. You agree that we have no obligation to monitor, review or evaluate the legality of your Transactions and that such actions shall be your sole responsibility. We are not liable for any prohibited use or misuse of the Card whatsoever.</p>

      <h2 id="section-4">4. Using the Online Services and Contact Details</h2>
      <p>4.1 The Online Services are provided by EasyTip in its capacity as Agent to NymCard and are provided to you to gain access to your Card and Account and other payment services.</p>
      <p>4.2 For any technical issues arising from the use of the Online Services only, please contact EasyTip through the customer support chat function in the EasyTip App or by email at: <a href="mailto:info@easytip.net">info@easytip.net</a></p>
      <p id="section-4_3">4.3 Should you have any queries in relation to the Card or the Account, you can reach NymCard's Customer Support using the dedicated channel provided on your Online Services, by email at <a href="mailto:support@nymcard.com">support@nymcard.com</a> or by telephone at 600505005 inside the UAE or <a href="tel:+971600505005">+971600505005</a> from outside the UAE.</p>

      <h2 id="section-5">5. Loading and Re-loading (Funding)</h2>
      <p>5.1 The Account and Card can only be funded by us. The Card can be Loaded in any Supported Currency as permitted.</p>
      <p>5.2 Your Available Funds are safeguarded in a bank account with Mashreq Bank PSC and will only be used for settling any Transactions conducted using your Card and any applicable fees and charges as detailed in the Fee Schedule.</p>
      <p>5.3 Unless stated otherwise, Loaded amounts will be credited to the Available Funds when the Issuer receives such amounts directly or indirectly through the permitted channels.</p>
      <p>5.4 If a requested Load amount exceeds the maximum balance permitted by us from time to time, then at our discretion any excess amount shall not be Loaded and shall be returned to its point of origin.</p>
      <p>5.5 We are authorized to withhold any Load and will not release the funds in relation to such Load if we have reasonable grounds to believe that the relevant funds are from suspicious, illegal or illegitimate sources. We will contact you for further information and such other necessary proof (to our satisfaction) on the source of funds for such Loads. If we are not satisfied with such information or proof, we are authorized to surrender such amounts, without any further notice to you, to the relevant law enforcement authorities for further investigation and/or legal action.</p>
      <p>5.6 You irrevocably authorize us to recover any funds erroneously Loaded to your Card. If an authorized Loading or your Card occurs, if any error occurs with respect to a Loading, or if you require additional information regarding a Loading, you must contact us immediately.</p>

      <h2 id="section-6">6. Keeping your Card and PIN secure</h2>
      <p>6.1 You may be required to sign the back of the Card as soon as you receive it if it has a signature strip on the back.</p>
      <p>6.2 You and the Card User must keep the Card safe and the PIN and other security details (including but not limited to identification number, password, and/or any other code that you use to access the Online Services, the Account or the Card) confidential and safe at all times and not disclose them to any third-party. In case you breach the aforementioned obligation, or fail to comply with it due to your own negligence or willful misconduct, you shall be solely responsible for the losses resulting from such breach or non-compliance and you undertake to reimburse the related losses incurred by us or any third party.</p>
      <p>6.3 You must never: (i) allow anyone else to use the Card, your PIN or other security details relating to your Card or Account; (ii) write down your PIN or any other security information related to the Card or your Account; or (iii) provide your PIN to any other person.</p>
      <p>6.4 We will never ask you to reveal your PIN, whether via Online Services, by email, by telephone or otherwise.</p>
      <p>6.5 Your PIN may be disabled if an incorrect PIN is entered three (3) times. If your PIN is disabled, please contact us for assistance. If your PIN has been disabled, or if a Merchant does not accept Chip and PIN cards, you will be required to sign for any Transactions, provided that this is supported by and acceptable to the Merchant.</p>
      <p>6.6 In the event of loss, theft or disclosure to a third party of your PIN or any password or other security details by you or the Card User,  or if you believe that a third party may attempt to wrongfully access your Account, you must change your PIN or password or such other security details immediately.</p>
      <p>6.7 You must promptly notify us by email, phone or by using the “Contact Us” function available on the Online Services of any loss or theft of the Card or your PIN or in case you are unable to change your PIN or password as required under section 6.5. We will suspend the Card to prevent further use. If a lost Card is subsequently found, it must not be used unless we confirm it may be used. We will not be liable for any misuse of the Card or unauthorized withdrawal arising out of the loss, theft or misuse of the Card or the PIN or password or if you fail to notify us of the same. If your Card is lost or stolen, you can receive a Replacement Card, subject to you providing the necessary documents and information. The issuance of a Replacement Card may be subject to a Card Issuing Fee as set out in the Fee Schedule.</p>
      <p>6.8 You will be required to confirm details of any loss, theft or misuse to us in writing through the channel made available or through the call centre and you must assist us, the police and any other official investigation authority in any enquiries.</p>
      <p>6.9 We may refuse to approve a Transaction if we suspect that the Transaction is illegal or that such Transaction would breach these Terms and Conditions. We will inform you of any such refusal, unless doing so is prohibited by law or would otherwise compromise fraud prevention or security measures.</p>
      <p>6.10 We may suspend the Card, at any time, with or without notice, if we think the Card has been or is likely to be used illegally or in breach of these Terms and Conditions or other misused. We may also suspend the Card upon request of the associated card scheme or any authorized regulator. We will inform you of any such suspension, unless doing so is prohibited by law or would otherwise compromise fraud prevention or security measures.</p>

      <h2 id="section-7">7. Unauthorized Transactions</h2>
      <p>7.1 You must notify us of any unauthorized Transactions by telephone at the numbers set out in <a href="#section-4_3">Section 4.3</a> above without undue delay and in any event no later than six months (6) months after the Transaction debit date. We may request that you provide additional written information concerning any such Transaction and you must comply with any such request.</p>
      <p>7.2 You will be liable for all losses in respect of the Card if you have: (i) acted fraudulently; (ii) failed to use the Card in accordance with these Terms and Conditions; (iii) failed to notify us without undue delay on becoming aware of the loss, theft or misuse of the Card; or (iv) failed to take all reasonable steps to keep the Card’s security features safe.</p>
      <p>7.3 Where you are not liable for an unauthorized Transaction, we will refund the value of that Transaction and will have no further liability to you for any other losses you may suffer. If our investigations conclude that the Transaction you have disputed has been authorized by you, or on your behalf, or you have acted fraudulently, we will not refund the value of the Transaction.</p>
      <p>7.4 You should check your Transaction history regularly. If you notice an error or a card Transaction that you do not recognise, you must tell us as soon as possible and no later than (2) months from the date of the Transaction debit date.</p>
      <p>7.5 We may also ask for additional information to help us investigate. We will not refund any unauthorised Transaction if we think that:</p>
      <ul>
        <li>You have acted fraudulently;</li>
        <li>You have intentionally or with gross negligence failed to use the Card in accordance with these Terms and Conditions;</li>
        <li>You have intentionally or with gross negligence failed to take reasonable steps to keep the personalised security details safe; or</li>
        <li>You have failed to notify us without undue delay.</li>
      </ul>
      <p>7.6 Even if we have refunded you for any unauthorised Transaction, we may later deduct the amount refunded from your Card if after further investigation we conclude that you authorised the Transaction or you were not entitled to a refund or you acted fraudulently.</p>

      <h2 id="section-8">8. Merchant Disputes</h2>
      <p>8.1 If you have a dispute concerning the quality of goods or services purchased with the Card, we will not be able to refund you unless the Merchant agrees.  If you have agreed that a Merchant can take payment from your Card, we will only be able to initiate your claim for a refund if one of the following conditions apply:</p>
      <ul>
        <li>the amount charged exceeds the amount you reasonably expected and agreed to pay; or</li>
        <li>You did not receive the goods or services purchased.</li>
      </ul>
      <p>8.2 You must also give us any additional information that we ask for that is reasonable, for us to investigate whether you are entitled to a refund.</p>

      <h2 id="section-9">9. Fees and Limits</h2>
      <p>9.1 Fees and Limits apply to the Card, as indicated in the EasyTip App and the Fee Schedule. The fees we charge are subject to change from time to time, in accordance with this Agreement. You will be notified in advance of any change in the applicable fees through the EasyTip App.</p>
      <p>9.2 You agree to pay, and authorize us to debit the Available Funds, for the fees set out in the EasyTip App and the Fee Schedule. These fees may be debited from the Available Funds as soon as they are incurred.</p>
      <p>9.3 From time to time, we may limit the amount you can spend at Merchants over certain time periods.</p>
      <p>9.4 Certain Merchants may charge additional fees if the Card is used to purchase goods or services. These fees are determined and charged by the Merchant who includes such fees in the Transaction amount and are not retained by us.</p>
      <p>9.5 Dynamic Currency Conversion (DCC) is a process whereby the amount of a Transaction is converted by a merchant to a currency other than the local currency of the country where such Merchant is located. In such cases, the Merchant may apply a foreign exchange margin to the applicable currency conversion rate. This may result in increased costs for your Transaction.</p>
      <p>For instance, if you withdraw cash from an ATM that dispenses a currency other than that of the country where the ATM is located, the Merchant or ATM operator may convert the cash being withdrawn into the domestic (local) currency before converting it back to the cash being dispensed. This may occur even though the cash being dispended is the same as that of one of your Supported Currencies.</p>
      <p>9.6 The foreign currency equivalent for the above-listed amounts will be determined based on the Conversion Rate or the applicable currency conversion rate used by VISA from time to time, as applicable.</p>
      <p>9.7 For the purposes of this section, a “<b>day</b>” is defined as a 24-hour period commencing at 12:00 midnight.</p>

      <h2 id="section-10">10. Foreign Currency Transactions</h2>
      <p>10.1 A foreign exchange rate will apply in the following instances: (i) initial Load or Reload(s) using the card for a Transaction in an Unsupported Currency; (ii) POS Terminal Transactions where the Transaction is in an Unsupported Currency; (iii) ATM withdrawals where the withdrawal currency is an Unsupported Currency.</p>
      <p>10.2 If a foreign exchange conversion takes place, the conversion will be undertaken at the prevailing exchange rate determined when funding your Account and Card.</p>
      <p>10.3 Foreign exchange rates are subject to variation and the rate that applies one day will not necessarily be the same on any other day.</p>
      <p>10.4 Additional margins may apply in respect of foreign exchange conversions.</p>

      <h2 id="section-11">11. Closing Your Account</h2>
      <p>11.1 To close your Account, please contact EasyTip Customer Support via one of the methods set out in <a href="#section-4_3">Section 4.3</a> above. You can spend your Available Balance by transacting at a Merchant or, if applicable, you can request your Available Balance be returned to you via Withdrawal & Account Closure service. All returns may be subject to Transaction Processing Fees as per the Fee Schedule, and uncollected Transactions if any.</p>
      <p>11.2 We will refund any balance to you in the Base Currency and any amounts in Unsupported Currencies will be converted using the Conversion Rate in effect on the date your Account is closed. Upon closure of your Account, you may be asked to destroy or return the Card to us. In any event, the Card linked to your Account will be automatically cancelled.</p>

      <h2 id="section-12">12. Redemption: Redeeming Unspent Funds</h2>
      <p>12.1 You may transfer the balance of any unspent Available Funds to your bank account. Payment will only be made to a UAE bank account held in your name. Any funds so transferred will be transferred in the Base Currency.</p>
      <p>12.2 A Transaction Processing Fee may be charged for transferring unspent Available Funds: (i) you make such a transfer prior to the Termination Date, subject to a cooling off period of 5 business days; (ii) you transfer unspent Available Funds and terminate this Agreement; or (iii) you transfer unspent Available Funds after the Card has expired.  Any applicable Monthly Account Fees will be deducted from any such transfer.</p>

      <h2 id="section-13">13. Termination of this Agreement</h2>
      <p>13.1 This Agreement shall terminate on the expiry of the Account and Card issued to you under this Agreement but will not affect any of our rights.</p>
      <p>13.2 We may terminate this Agreement prior to the Termination Date by giving you at least two (2) months’ written notice by letter or email to the address you have provided us.</p>
      <p>13.3 You may terminate this Agreement prior to the Termination Date at any time by writing to or emailing us.</p>
      <p>13.4 Upon any termination of this Agreement in accordance with these Terms and Conditions, you will no longer be able to use the Card. The termination of this Agreement will not affect your right (if any) to transfer any unspent Available Funds in accordance with <a href="#section-12">Section 12</a> above.</p>
      <p>13.5 This Agreement will terminate automatically if you cancel your subscription to the EasyTip App.</p>

      <h2 id="section-14">14. Changing the terms and conditions</h2>
      <p>14.1 You are deemed to have read, understood and agreed to be bound by this Agreement. At any time, we may change, add, amend, supersede or supplement any or all of these Terms and Conditions. Such amendments will be available on the EasyTip website or the EasyTip App and will be effective from the date of such change.</p>
      <p>14.2 We may amend these Terms and Conditions from time to time, in order to comply with applicable laws and regulations.</p>

      <h2 id="section-15">15. Personal Data</h2>
      <p>15.1 We do our best to notify You of changes at least two (2) months before the change is implemented on the website, with exception that changes that need to be implemented immediately by law</p>
      <p>15.2 We collect, protect, control, process and retain our Customers' Personal Data for the purposes of providing payment services to you or otherwise to fulfil this Agreement. in accordance with the local applicable data protection laws and regulations including but not limited to the Protection of Personal Data issued by the Central Bank of UAE dated 25/11/2020 (Circular No. 8 – 2020), the UAE Federal Decree Law No. 45/2021 on (the “PDPL”) the ADGM Data Protection Regulations 2021.</p>
      <p>15.3 We take all the necessary measures to protect your Personal Data against unauthorised or unlawful processing, accidental disclosure, loss, destruction, or damage. This includes legal, organizational, technical, and physical security measures.</p>
      <p>15.4 Your Personal Data will be processed if:</p>
      <p>a) You have given consent to the processing of your Personal Data for one or more specific purposes;</p>
      <p>b) processing is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract;</p>
      <p>c) processing is necessary for compliance with a legal obligation to which we are subject to; or</p>
      <p>d) processing is necessary for the purposes of the legitimate interests pursued by us.</p>
      <p>15.5 If you provide your consent to the processing of your Personal Data, you have the right to withdraw your consent at any time by sending us an email with a request at <a href="mailto:dataprotection@NymCard.com">dataprotection@NymCard.com</a> or through the Online Services available.</p>
      <p>15.6 Why and how do we collect your Personal Data, and what do we do with it?</p>
      <p>a) We collect your Personal Data to identity establishment and verification, anti-money laundering and terrorist financing measures, provision of Card Services, execution of contractual and other legal obligations</p>
      <p>b) Information we may collect: your name, surname, nationality, personal number, date of birth, number of your ID document, a copy of your ID document, address, a photo, email address, phone number, payment account number, IP address, occupation and other information necessary to comply with anti-money laundering and terrorist financing requirements and to provide you with Card Services.</p>
      <p>c) We collect this data directly from you and/or from third parties including but not limited to identity verification service providers and financial sanctions databases.</p>
      <p>d) We will store your data for 5 years as of the completion of a transaction or a business relationship.</p>
      <p>e) We can provide this data to supervising institutions, credit, financial, payment and (or) electronic money institutions, courts, law enforcement institutions, tax authorities, payment receivers, lawyers, auditors, other subjects with a legal access to this information and subjects with whom we have a contract but only if you give your consent.</p>
      <p>f) The storage and processing of your Personal Data are governed by the laws of the UAE and these User Terms and Conditions. If you refuse to provide this data and or/information or withdraw your consent, we retain the right not to enter into or terminate this Agreement with you and to refuse to provide you with our Card Services.</p>
      <p>15.7 We may contact you by telephone, letter, SMS or email at the contact details you provide us. You must let us know immediately if you change your name, address, phone number or email address.</p>
      <p>15.8 You confirm that you have provided complete and correct information and data about yourself and that afterwards, when changing or adding any data, you will enter only correct data. We will not tolerate invalid, false, incomplete or  incorrect data and will pursue actions in accordance with its legal obligations. You shall bear any losses that occur with regard to the submission of invalid, false, incomplete or incorrect data.</p>
      <p>15.9 By requesting the issuance of or using the Account and Card Services, you authorize us to disclose and furnish to our authorized representatives, subsidiaries, associates, branches, assignees, agents or other connected parties (including the Program Manager and/or our third-party advisors) such information as we deem fit concerning you, including but not limited to, the Card Services governed by this Agreement.</p>
      <p>15.10 Your Personal Data may be processed outside of the country where the Card is issued, but we are required to have adequate safeguards in place to protect your Personal Data provided that such processing is approved by the UAE Data Protection Applicable Laws and Regulations.</p>

      <h2 id="section-16">16. Liability</h2>
      <p>16.1 The Card is issued entirely at your risk. We shall bear no liability whatsoever for any loss or damage arising from the issuance of the Card. The use of the Card shall be at your sole risk. By requesting the issuance of or using the Card you confirm assuming any and all financial risks incidental to or arising out of the Card’s usage with no further liability to us.</p>
      <p>16.2 You agree to fully indemnify us and hold us harmless against any and all actions, proceedings, costs, losses or damages (including legal costs) you may suffer in connection with the usage of the Card, any misuse of the Card, PIN and/or other facilities provided to you in relation to the Card and Account. This includes any liability arising from your failure to maintain safe custody of the Card at any time before the Card is cancelled.</p>
      <p>16.3 We will not be liable to you for: delays or mistakes resulting from you for breaching any of your obligations mentioned in those Terms and Conditions, your willful misconduct or gross negligence or any circumstances beyond our control, including, without limitation: (i) acts of governmental authorities, national emergencies, insurrection, war, or riots; (ii) the failure of Merchants to accept or honor the Card; (iii) the failure of Merchants to perform or provide services or goods; (iv) communication system failures; (v) interception of communications; or (vi) mechanical defects, failures or malfunctions attributable to your equipment, any internet service, or any payment system.</p>
      <p>16.4 We are not responsible for ensuring that ATMs and POS Terminals will accept the Card, or for the way in which a Merchant processes a Transaction. We will not be liable to you for any non-acceptance of the Card, for disputes concerning the quality of goods or services purchased on the Card or any additional fees charged by the operators of these terminals (for example, when you withdraw currency from an ATM, the ATM operator may charge you an additional fee for the service).</p>
      <p>16.5 Information sent over the internet may not be completely secure and the internet and the online systems are not controlled or owned by us. Therefore, we cannot guarantee that they will function at all times, and we accept no liability for unavailability or interruption, or for the interception or loss of any information or other data.</p>
      <p>16.6 We will not be liable under any of the following circumstances:</p>
      <p>a) If, through no fault of ours, there are insufficient funds available on the Card to complete a Transaction;</p>
      <p>b) If the Card is not honoured, is declined, is damaged or is confiscated at any ATM or POS Terminal for any reason;</p>
      <p>c) If the Card is not honoured, or is declined at any Internet Merchant location for any reason;</p>
      <p>d) If an ATM where you are making a cash withdrawal does not have enough cash;</p>
      <p>e) If an electronic terminal where you are making a Transaction does not operate properly;</p>
      <p>f) If a Load has been declined due to our discretion;</p>
      <p>g) If access to the Card has been blocked after you have reported the Card lost or stolen;</p>
      <p>h) If there is a hold or your funds are subject to legal process or other encumbrance restricting their use;</p>
      <p>i) If we (in our sole discretion) have reason to believe the requested Transaction is unauthorized;</p>
      <p>j) If circumstances beyond our control (such as fire, flood or computer or communication failure) prevent the completion of the Transaction, despite reasonable precautions that we have taken;</p>
      <p>k) For any consequential damages, extraordinary damages, special or punitive damages; and</p>
      <p>l) In any other circumstances except as expressly stated otherwise in these Terms and Conditions.</p>
      <p>16.7 In the event that we are found to be liable to you for any breach of these Terms and Conditions, to the extent permitted by law, you will only be entitled to recover your actual damages. In no event shall you be entitled to recover any indirect costs (including legal costs) consequential, exemplary or special damages (whether in contract, tort or otherwise), even if you have advised us of the possibility of such damages. This provision shall not be effective to the extent otherwise required by law.</p>

      <h2 id="section-17">17. Disclaimers</h2>
      <p>17.1 We shall be absolved of any liability if:</p>
      <p>a) You are unable to use the Card due to force majeure conditions including but not limited to not being in the required geographical range or any other reason including natural calamities; legal restraints or any technical lapses in the telecommunication network or any other reasons beyond our actual control. Also, we are herein absolved of any kind of liability arising due to a loss; direct or indirect incurred by you or any other person due to any lapse in the facility owing to the above-mentioned reasons.</p>
      <p>b) there is any unauthorized use of the PIN, or for any fraudulent, duplicate or erroneous Transaction instructions given by use of the PIN (unless confirmed by us to you that the Card is blocked or cancelled);</p>
      <p>c) there is loss of any information during processing or transmission or any unauthorized access by any other person or breach of confidentiality;</p>
      <p>d) there is any lapse or failure on the part of the service providers or any third-party affecting the usage of the Card (and for this purpose, we make no warranty as to the quality of the service provided by any such provider);</p>
      <p>e) any loss or damage whether direct, indirect or consequential, including but not limited to loss of business, contracts, or goodwill, loss of use or value of any equipment including software, whether foreseeable or not, suffered by you or any person howsoever arising from or relating to any delay, interruption, suspension, resolution or error by us in receiving and processing the request and in formulating and returning responses or any failure, delay, interruption, suspension, restriction, or error in transmission of any information or message to and from your telecommunication equipment and the network of any service provider and our system or any breakdown, interruption, suspension or failure of your telecommunication equipment, our system or the network of any service provider and/or any third-party who provides such services as is necessary to provide.</p>
      <p>17.2 We shall not be held liable for any loss incurred by you due to use of the Card by any other person with your express or implied permission. We shall not be held responsible for the confidentiality, secrecy and security of the personal or account information being transmitted for effecting your instructions.</p>
      <p>17.3 We shall not be held liable for any loss suffered by you due to disclosure of the Personal Data to a third-party by us, for reasons inclusive but not limited to participation in any telecommunication or electronic clearing network, in compliance with a legal directive, for statistical analysis or for credit rating. We will endeavour to make sure the Card continues to function, but its operation may be subject to interruptions and/or require periodic modifications and improvements; and to help reduce the risks, we may from time to time set revised limitations on the Transaction size, Loading amounts and other features of the Card.</p>

      <h2 id="section-18">18. Communication and Notices</h2>
      <p>18.1 We undertake to communicate with you in English  and / or Arabic regarding any aspect of the Card.</p>
      <p>18.2 You agree that we may communicate with you by email or SMS or through the website or the mobile application for issuing any notices or information about the Card and therefore it is important that you ensure that you keep your email address or mobile phone number updated and regularly check the applicable website and mobile application.</p>
      <p>18.3 We may record and shall keep a record of your application form, identification documents, and request forms as well as any electronic, written or verbal communications for as long as we consider appropriate. This information will be kept secure as per the requirements of the applicable data protection law in the UAE.</p>
      <p>18.4 You agree that all the telephone calls between you and us may be tape recorded by us and that such recording may be submitted in evidence in any proceedings.</p>
      <p>18.5 Save as otherwise provided in this Agreement, any demand or communication made by us under the Agreement will be in writing in English and/or Arabic and made at the address given by you (or such other address as you may notify us from time to time).</p>
      <p>18.6 Any notice sent by either party under the Agreement by email shall be deemed given on the next day the email is sent, unless the sending party received an electronic indication that the email was not delivered; and if by post, shall be deemed received ten (10) Business Days after the date of posting.</p>
      <p>18.7 Legal notices may be sent to <a href="mailto:legal@nymcard.com">legal@nymcard.com</a>.</p>

      <h2 id="section-19">19. General Provisions</h2>
      <p>19.1 In addition to any general right to set-off or other rights available to us under any applicable law, you agree that we may in our absolute discretion at any time and without notice combine and consolidate all or any amounts in any Card or account to set-off or transfer any amount in such Card in or towards discharge of all amounts due to us under any Card with us whether in the Base Currency or any other currency and may do so notwithstanding that the balances on such Cards or Account and the sums due may not be expressed in the same currency and you hereby authorize us to offset any such combination, consolidation, set-off or transfer with the necessary conversion at our prevailing exchange rates which shall be determined by us.</p>
      <p>19.2 No forbearance, neglect or waiver by us in the exercise or enforcement of any right or remedy arising from this Agreement will prejudice our right thereafter to strictly enforce the same.</p>
      <p>19.3 No waiver by us will be effective unless it is in writing.</p>
      <p>19.4 If any provision in this Agreement is found to be unenforceable, invalid or illegal, such provision will be deemed to be deleted and the remainder of these Terms and Conditions will be unaffected by such unenforceability, invalidity or illegality. We shall not be liable for our inability to pay due to restrictions on convertibility or transferability, requisitions, involuntary transfers, acts of war or civil strife or other similar causes beyond our control. In such event no other office, branch, subsidiary or affiliate of ours shall be responsible or liable to you.</p>
      <p>19.5 We are entitled to record all communication and conversations (including telephone conversations) with you and/or any authorized representative of yours and messages and/or instructions sent to us whether by telephone, in person, voice, use of a touch-tone key pad, electronically or otherwise and Transactions conducted by or through any such medium. Our record of all such communications, conversations, instructions, messages and Transactions will be conclusive and binding on you for all purposes.</p>
      <p>19.6 We may assign any of our rights and obligations under this Agreement, without your prior consent, to any third-party, subject to such party continuing the obligations in this Agreement to you. You are not permitted to assign your rights and obligations under this Agreement to any third-party, and any purported assignment in contravention of this Section 19.6 will be void.</p>

      <h2 id="section-20">20. Governing Law and Jurisdiction</h2>
      <p>20.1 The Agreement and any disputes, which arise under it, shall be governed by the laws of the country in which the Card is issued and the applicable federal laws of the United Arab Emirates (UAE).</p>
      <p>20.2 You agree that the courts of the UAE have exclusive jurisdiction to settle any dispute arising out of or in connection with this Agreement (including a dispute relating to the existence, validity or termination of this Agreement or any non-contractual obligation arising out of or in connection with this Agreement) and any proceedings arising out of or in connection with this Agreement shall be brought in such courts.</p>

      <h2 id="section-annex-1">ANNEX 1: Fees and Charges</h2>
      <p>This section below is for the purpose of providing context to how this Annex is managed.</p>
      <p>Depending on the product type issued the End User will potentially be charged some fees for the use of the payment card. Depending on the product types issued to user there are different benefits attached to the cards hence higher fees will be reflected on the type of car.  These fees will be vetted and approved by NymCard ensuring that they are in line with the CBUAE consumer protection regulations and guidelines.  Below are the indicative price points that may be charged to the end users – This section will differ from one Client/Programme to another but we will abide by the below communicated fee ranges:</p>
      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Fee</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Card Issuing Fee</td>
            <td>Free</td>
            <td>Subject to EasyTip subscription</td>
          </tr>
          <tr>
            <td>Annual Card Fees</td>
            <td>Free</td>
            <td>Subject to EasyTip subscription</td>
          </tr>
          <tr>
            <td>Transaction Processing Fees</td>
            <td>Free</td>
            <td>Subject to EasyTip subscription and/or Transaction Type (Local vs. International)</td>
          </tr>
          <tr>
            <td>FX Markup</td>
            <td>1% - 3%</td>
            <td>On Foreign Currency Transactions</td>
          </tr>
          <tr>
            <td>Monthly Account Fees</td>
            <td>Free</td>
            <td>Subject to EasyTip subscription</td>
          </tr>
          <tr>
            <td>Transfer out Fees</td>
            <td>3 AED</td>
            <td>Subject to EasyTip subscription</td>
          </tr>
          <tr>
            <td>Card Cancelation Fee</td>
            <td>Up to 20 AED</td>
            <td>Subject to EasyTip subscription; 20 AED or user card balance if the card balance is less than 20 AED.</td>
          </tr>
          <tr>
            <td>Card Re-issuance Fee</td>
            <td>5 AED</td>
            <td>Subject to EasyTip subscription</td>
          </tr>
        </tbody>
      </table>
      <div class="document__gradient" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NymTerms',
};
</script>
