import request from './request';

export default {
  getFilePages: ({
    locationId,
    fileName,
    data,
  }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/menu/files`,
    data: {
      data,
      fileName,
    },
  }),
  getFiles: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/menu/files`,
  }),
  getBackground: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/menu/background`,
  }),
  setBackground: ({
    locationId,
    fileName,
    data,
  }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/menu/background`,
    data: {
      data,
      fileName,
    },
  }),
  deleteBackground: ({ locationId }) => request.easytip({
    method: 'delete',
    url: `/v3/workplaces/${locationId}/menu/background`,
  }),
  getContactInfo: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/menu/contacts`,
  }),
  setMenuData: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/workplaces/${locationId}/menu`,
    data,
  }),
};
