import request from './request';

export default {
  getList: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/accountants`,
  }),
  create: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/accountants/registration`,
    data,
  }),
  deactivate: ({ locationId, id }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/accountants/${id}`,
  }),
};
