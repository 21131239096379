import request from './request';

export default {
  getList: ({ locationId }) => request.easytip({
    method: 'get',
    url: `/v2/restaurants/${locationId}/troncmasters`,
  }),
  create: ({ locationId, ...data }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/troncmasters/registration`,
    data,
  }),
  deactivate: ({ locationId, id }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/troncmasters/${id}`,
  }),
  delegateStaff: ({ locationId, staffId }) => request.easytip({
    method: 'post',
    url: `/v2/restaurants/${locationId}/troncmasters/${staffId}/delegate`,
  }),
  undelegateStaff: ({ locationId, staffId }) => request.easytip({
    method: 'delete',
    url: `/v2/restaurants/${locationId}/troncmasters/${staffId}/delegate`,
  }),
};
