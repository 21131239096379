/* eslint-disable import/no-cycle */

import request from './request';

export default {
  getProfile: () => request.easytip({
    method: 'get',
    url: '/v2/users/profile',
  }),
  getSalesProfile: () => request.easytip({
    method: 'get',
    url: '/v2/sales/profile',
  }),
  updateProfile: ({
    email,
    firstName,
    lastName,
    wish,
    dateOfBirth,
    staffType,
    taxReportingType,
    nickName,
  }) => request.easytip({
    method: 'post',
    url: '/v2/users/profile',
    data: {
      email,
      firstName,
      lastName,
      wish,
      dateOfBirth,
      staffType,
      taxReportingType,
      nickName,
    },
  }),
  updateSalesProfile: ({
    id,
    firstName,
    lastName,
    country,
  }) => request.easytip({
    method: 'put',
    url: '/v2/sales/profile',
    data: {
      id,
      firstName,
      lastName,
      country,
    },
  }),
  applyTerms: ({ id }) => request.easytip({
    method: 'post',
    url: `/v2/users/${id}/term`,
  }),
  applyDistributions: ({ id }) => request.easytip({
    method: 'post',
    url: `/v2/users/${id}/distributionsRule`,
  }),
  applyNymTerms: ({ id }) => request.easytip({
    method: 'post',
    url: `/v4/users/${id}/nym/terms`,
  }),
  getReviewsImages: ({ name, quality }) => request.media({
    method: 'get',
    url: `/img/reviews/${quality}/${name}.jpg`,
  }),
  sendAdditionalInfo: ({ id, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/users/${id}/additionalInfo`,
    data,
  }),
  sendBankDetails: ({ id, ...data }) => request.easytip({
    method: 'post',
    url: `/v3/users/${id}/bankAccountInformation`,
    data,
  }),
  sendAdditionalInfoScan: ({ id, file, side }) => {
    const data = new FormData();

    data.append('file', file);
    data.append('side', side);

    return request.easytip({
      method: 'post',
      url: `/v3/users/${id}/additionalInfo/scan`,
      data,
    });
  },
  getUserStaffs: ({ staffId }) => {
    return request.easytip({
      method: 'get',
      url: `/v3/users/${staffId}/staffs`,
    });
  },
};
