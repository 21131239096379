import request from './request';

export default {
  getOtp: ({ userId, otpType }) => request.easytip({
    method: 'get',
    url: `/v4/users/${userId}/otp/code`,
    params: {
      otpType,
    },
  }),
  verify: ({
    userId, id, otpCode, otpType,
  }) => request.easytip({
    method: 'post',
    url: `/v4/users/${userId}/otp/verify`,
    data: {
      id,
      otpCode,
      otpType,
    },
  }),
};
