import manager from './manager';

const {
  getImage,
  uploadImage,
  updatePassword,
} = manager;

export default {
  getImage,
  uploadImage,
  updatePassword,
};
