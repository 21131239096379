import request from './request';

export default {
  getImage: () => request.easytip({
    method: 'get',
    url: '/v1/admin/settings/image',
    responseType: 'blob',
  }),
  uploadImage: (file) => {
    const data = new FormData();

    data.append('file', file);

    return request.easytip({
      method: 'post',
      url: '/v1/admin/settings/image',
      data,
    });
  },
  updatePassword: (data) => request.easytip({
    method: 'post',
    url: '/v1/admin/settings/password/update',
    data,
  }),
  updatePhone: (data) => request.easytip({
    method: 'post',
    url: '/v1/admin/settings/phone/update',
    data,
  }),
  confirmPhone: (data) => request.easytip({
    method: 'post',
    url: '/v1/admin/settings/phone/confirm',
    data,
  }),
  getPhoneToken: (token) => request.easytip({
    method: 'get',
    url: '/v1/admin/settings/phone/token/refresh',
    params: {
      token,
    },
  }),
  resetOtpTryouts: ({ id }) => request.easytip({
    method: 'post',
    url: `/v4/admin/unlock/${id}`,
  }),
  changePhone: ({ userId, otpId, phone }) => request.easytip({
    method: 'post',
    url: `/v4/admin/user/${userId}/phone/update`,
    data: {
      phone,
      otpId,
    },
  }),
};
