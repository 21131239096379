import { getContentDispositionFilename } from '@/utils/parseHeader';
import request from './request';

export default {
  getPromotions: (params) => request.easytip({
    method: 'get',
    url: '/v1/promotions',
    params,
  }),
  getPromotionsExcel: ({ promotionId }) => request.easytip({
    method: 'get',
    url: `/v1/promotions/${promotionId}/excel`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }).then((res) => {
    return {
      data: res.data,
      filename: getContentDispositionFilename(res.headers),
    };
  }),
  createPromotion: (data) => request.easytip({
    method: 'post',
    url: '/v1/promotions',
    data,
  }),
  getPromotion: ({ promotionId }) => request.easytip({
    method: 'get',
    url: `/v1/promotions/${promotionId}`,
  }),
  updatePromotion: ({ promotionId, ...data }) => request.easytip({
    method: 'put',
    url: `/v1/promotions/${promotionId}`,
    data,
  }),
  cancelPromotion: ({ promotionId }) => request.easytip({
    method: 'post',
    url: `/v1/promotions/${promotionId}/cancel`,
  }),
  completePromotion: ({ promotionId }) => request.easytip({
    method: 'post',
    url: `/v1/promotions/${promotionId}/complete`,
  }),
  sendNotification: ({ promotionId, ...data }) => request.easytip({
    method: 'post',
    url: `/v1/promotions/${promotionId}/notification`,
    data,
  }),
};
