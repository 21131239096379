import axios from 'axios';
import instance from './instance';

/**
 * Create cancelable request.
 *
 * @param {AxiosInstance} instance
 * @return {Function}
 */
function createCancelable(axiosInstance) {
  return (config) => {
    const source = axios.CancelToken.source();
    const req = axiosInstance.request({
      ...config,
      cancelToken: source.token,
    });

    req.cancel = source.cancel;

    return req;
  };
}

export default {
  easytip: createCancelable(instance.easytip),
  intercom: createCancelable(instance.intercom),
  media: createCancelable(instance.media),
};
