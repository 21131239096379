import request from './request';

export default {
  getAdmins: () => request.intercom({
    method: 'get',
    url: '/admins/',
  }),
  createUser: (data) => request.intercom({
    method: 'post',
    url: '/contacts/',
    data,
  }),
  updateUser: ({ userId, ...data }) => request.intercom({
    method: 'put',
    url: `/contacts/${userId}/`,
    data,
  }),
  getUser: ({ userId }) => request.intercom({
    method: 'get',
    url: `/contacts/${userId}/`,
  }),
  unarchiveUser: ({ userId }) => request.intercom({
    method: 'post',
    url: `/contacts/${userId}/unarchive`,
  }),
  searchUser: ({ external_id }) => request.intercom({
    method: 'post',
    url: '/contacts/search/',
    data: {
      query: {
        field: 'external_id',
        operator: '=',
        value: external_id,
      },
    },
  }),
  getAttributes: (params) => request.intercom({
    method: 'get',
    url: '/data_attributes/',
    params,
  }),
  createAttribute: (data) => request.intercom({
    method: 'post',
    url: '/data_attributes/',
    data,
  }),
  createConversation: ({ userId, message }) => request.intercom({
    method: 'post',
    url: '/conversations/',
    data: {
      from: {
        type: 'user',
        id: userId,
      },
      body: message,
    },
  }),
  getConversation: ({ conversationId }) => request.intercom({
    method: 'get',
    url: `/conversations/${conversationId}/`,
    params: {
      display_as: 'plaintext',
    },
  }),
  searchLastConversation: ({ userId }) => request.intercom({
    method: 'post',
    url: '/conversations/search',
    data: {
      query: {
        field: 'contact_ids',
        operator: '=',
        value: userId,
      },
    },
  }),
  assignConversation: ({ conversationId, adminId, assigneeId }) => request.intercom({
    method: 'post',
    url: `/conversations/${conversationId}/parts`,
    data: {
      message_type: 'assignment',
      type: 'admin',
      admin_id: adminId,
      assignee_id: assigneeId,
    },
  }),
  createMessage: ({ userId, conversationId, message }) => request.intercom({
    method: 'post',
    url: `/conversations/${conversationId}/reply/`,
    data: {
      message_type: 'comment',
      type: 'user',
      intercom_user_id: userId,
      body: message,
    },
  }),
};
