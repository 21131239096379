import toSQLDate from '@/utils/toSQLDate';
import request from './request';
import fake from './fake';

export default {
  getImage: () => request.easytip({
    method: 'get',
    url: '/v1/user/settings/image',
    responseType: 'blob',
  }),
  uploadImage: (file) => {
    const data = new FormData();

    data.append('file', file);

    return request.easytip({
      method: 'post',
      url: '/v1/user/settings/image',
      data,
    });
  },
  updatePassword: (data) => request.easytip({
    method: 'post',
    url: '/v2/user/settings/password/update',
    data,
  }),
  updateGeneratedPassword: (data) => request.easytip({
    method: 'post',
    url: '/v4/user/settings/password/update',
    data,
  }),
  updatePhone: (data) => request.easytip({
    method: 'post',
    url: '/v2/user/settings/phone/update',
    data,
  }),
  confirmPhone: (data) => request.easytip({
    method: 'post',
    url: '/v2/user/settings/phone/confirm',
    data,
  }),
  getPhoneToken: (token) => request.easytip({
    method: 'get',
    url: '/v1/user/settings/phone/token/refresh',
    params: {
      token,
    },
  }),
  getRating: ({
    locationId,
    staffId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/ratings/avg`,
    params: {
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getTotal: ({
    locationId,
    staffId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/transactions/total`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getBonus: ({
    locationId,
    staffId,
    groupId,
    dateFrom,
    dateTo,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/transactions/bonus`,
    params: {
      groupId,
      dateFrom: toSQLDate(dateFrom),
      dateTo: toSQLDate(dateTo),
    },
  }),
  getPromotions: ({
    locationId,
    staffId,
    status,
  }) => request.easytip({
    method: 'get',
    url: `/v3/workplaces/${locationId}/staffs/${staffId}/promotions`,
    params: {
      status,
    },
  }),
  getPayoutPeriod: ({
    isCheckout,
    locationId,
    staffId,
    userId,
    ...params
  }) => request.easytip({
    method: 'get',
    url: isCheckout
      ? `/v3/users/${userId}/payouts`
      : `/v3/workplaces/${locationId}/staffs/${staffId}/payouts`,
    params,
  }),
  sendRequestId: ({ staffId }) => request.easytip({
    method: 'post',
    url: `/v4/staffs/${staffId}/isRequiredAdditionalInfo`,
  }),
  cancelRequestId: ({ staffId }) => request.easytip({
    method: 'delete',
    url: `/v4/staffs/${staffId}/isRequiredAdditionalInfo`,
  }),
  sendRequestBankDetails: ({ staffId }) => request.easytip({
    method: 'post',
    url: `/v4/staffs/${staffId}/isRequiredBankAccountInformation`,
  }),
  cancelRequestBankDetails: ({ staffId }) => request.easytip({
    method: 'delete',
    url: `/v4/staffs/${staffId}/isRequiredBankAccountInformation`,
  }),
  getStaffIdInfo: ({ staffId }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/${staffId}/additionalInfo`,
  }),
  getAdditionalInfoScan: ({ staffId, side }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/${staffId}/additionalInfo/scan`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
    params: {
      side,
    },
  }).then((res) => {
    return {
      data: res.data,
    };
  }),
  getStaffScan: ({ staffId, scanId }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/${staffId}/scan/${scanId}`,
    headers: {
      Accept: '*/*',
    },
    responseType: 'blob',
  }).then((res) => {
    return {
      data: res.data,
    };
  }),
  getStaffs: (data, params) => request.easytip({
    method: 'post',
    url: '/v4/staffs',
    data,
    params,
  }),
  getStaffDetails: ({ staffId }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/${staffId}/details`,
  }),
  getBankDetails: ({ staffId }) => request.easytip({
    method: 'get',
    url: `/v4/staffs/${staffId}/bankAccountInfo`,
  }),
  suspendNymCard: ({ userId, cardId }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}/cards/${cardId}/suspendNymCard`,
  }),
  activateNymCard: ({ userId, cardId }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}/cards/${cardId}/activateNymCard`,
  }),
  disableNymCard: ({ userId, cardId }) => request.easytip({
    method: 'put',
    url: `/v4/users/${userId}/cards/${cardId}/disableNymCard`,
  }),
};
