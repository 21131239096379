/**
 * @alias Timer
 */
class Timer {
  /**
   * Timer.
   *
   * @param {number} time
   * @param {function?} callback
   */
  constructor(time, callback = null) {
    this.time = time;
    this.callback = callback;
    this.start();
  }

  /**
   * Start timer.
   */
  start() {
    this.handle = setInterval(() => {
      this.time -= 1000;

      if (this.time <= 0) {
        this.stop();

        if (typeof this.callback === 'function') {
          this.callback();
        }
      }
    }, 1000);
  }

  /**
   * Stop timer.
   */
  stop() {
    if (this.handle) {
      clearInterval(this.handle);
    }
  }

  /**
   * Convert to string.
   *
   * @returns {string}
   */
  toString() {
    const time = Math.round(this.time / 1000);
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return [
      minutes >= 10 ? minutes : `0${minutes}`,
      seconds >= 10 ? seconds : `0${seconds}`,
    ].join(':');
  }
}

export default Timer;
